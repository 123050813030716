import { Component, OnInit } from '@angular/core';

import { BusService } from '../../core/services/bus/bus.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  constructor(private readonly busService: BusService) { }

  ngOnInit() {
    this.busService.stopLoading();
  }

}
