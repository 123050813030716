<div class="text-field {{ state }}">
    <div class="content">
        <label>
      <input 
        type="{{ type }}" 
        id="{{ id }}" 
        class="{{ inputStyle }}" 
        [(ngModel)]="valueInputPassword" 
        name="password-field"  
        [required]="true" 
        (change)="validateField()" 
        (keyup)="clearErrorStyle(); showErrorState()" 
        (click)="validateField()"
        autocomplete="off" 
        onPaste="return false" onCopy="return false" 
        input-directive 
        [minLength]=minLength 
        [maxLength]=maxLength 
        [pattern]="patternInput" 
        (setState)="onSetStateChange($event)"
        [disabled]="disabled" 
        [autofocus]='focusElement'
      >
      <span [ngClass]="{'disable-input' : state === 'disabled'}">{{ label }}</span>
      <div class="icon">
        <i id="{{idEye}}" [class]="class" (click)="togglePassword()"></i>
        <app-tooltip *ngIf="state !== 'error' && tooltipMessage" icon="fal fa-info-circle" 
        title="{{tooltipMessage}}"></app-tooltip>
        <i *ngIf="state === 'error'" class="fal fa-times error" (click)="clearField()" ></i>
      </div>
    </label>
    </div>
    <div class="message">
        <p id="{{ id + 'Message' }}">{{ message }}</p>
    </div>
</div>