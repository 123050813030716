import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiGateway } from '@settings/models/enviroment-consts/api-gateway';
import { AppJwtHelperService } from './jwt-helper-service';
import FingerprintJS from '@com-banistmo/fingerprintjs';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public token: string = '';
  public clientId: string | null = '';
  public username: string = '';
  public isMobile: boolean = false;
  public otp: string | undefined = '';
  public redirectURI: string | null = '';
  public state: string | null = '';
  public mfa_token: string | null= '';
  public nonce: string | null = '';
  public multiSessionToken: string = '';
  public captchaValue: string = '';
  public codeChallenge: string | null= '';
  public codeMethodChallenge: string | null = '';


  private readonly $keyPublic = new BehaviorSubject<string>('');

  constructor(private http: HttpClient, private appJwtHelper: AppJwtHelperService,
    private configService: ConfigService) {}

  public setSessionData(token: string, isMobile?: boolean, otp?: string) {
    this.setToken(token);
    this.isMobile = isMobile === true;
    this.otp = otp;
  }

  public setToken(token: string) {
    this.token = token;
    const decoded = this.appJwtHelper.decodeToken(this.token);
    this.clientId = decoded.aud;
    this.username = decoded.sub;
  }

  public setKeyPublic(key: string): void {
    this.$keyPublic.next(key);
  }

  public getKeyPublic(): any {
    return this.$keyPublic.value;
  }

  public getChannelStatus(channel: string | null): Observable<any>  {
    const httpOptions = {
      headers: new HttpHeaders({'Authorization': `bearer ${​​​​this.configService.config.token.value}` })
    };
    return this.http.get(ApiGateway.CHANNEL_STATUS + channel +"/status",httpOptions);
  }

  async getFingerPrint(): Promise<string> {
    const fp = await FingerprintJS.load({ debug: true });
    let fpGet = await fp.get();
 
    return fpGet.visitorId;
  }
}