import { Component, OnInit,ElementRef, Injectable  } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})

@Injectable({providedIn: 'root'})
export class AppComponent implements OnInit {
  public isDev = false;

  constructor(private readonly translate: TranslateService,private _elementRef: ElementRef ) { }

  ngOnInit() {
    this.translate.setDefaultLang('en');
    this._elementRef.nativeElement.removeAttribute("ng-version");
  }

}