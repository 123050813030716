import { NgModule } from '@angular/core';
import { NotificationComponent } from "./notification/notification.component";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgbDatepickerModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TextFieldComponent } from "./text-field/text-field.component";
import { TooltipComponent } from "./tooltip/tooltip.component";
import { PasswordFieldComponent } from "./password-field/password-field.component";
import { HyperlinkComponent } from "./hyperlink/hyperlink.component";
import { ButtonComponent } from "./button/button.component";
import { LoadingComponent } from "./loading/loading.component";
import { ModalComponent } from "./modal/modal.component";
import { StepProgressComponent } from './step-progress/step-progress.component';
import { PasswordValidatorComponent } from './password-validator/password-validator.component';
import { ProgressBarPassComponent } from './progress-bar-pass/progress-bar-pass';

@NgModule({
    declarations: [
      PasswordValidatorComponent,
      StepProgressComponent,
      ProgressBarPassComponent,
      TooltipComponent,
      ModalComponent,
      NotificationComponent,
      PasswordFieldComponent,
      ButtonComponent,
      LoadingComponent,
      HyperlinkComponent,
      TextFieldComponent],
    imports: [
      
      FormsModule,
      CommonModule,
      NgbModule,
      NgbDatepickerModule,
      TranslateModule.forChild()
    ],
    exports: [
      FormsModule,
      PasswordValidatorComponent,
      StepProgressComponent,
      ProgressBarPassComponent,
      TooltipComponent,
      ModalComponent,
      ButtonComponent,
      LoadingComponent,
      PasswordFieldComponent,
      HyperlinkComponent,
      NotificationComponent,
      TextFieldComponent],
    providers:[TranslateService]
  })
  export class SharedModule { }
  