import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { timer } from 'rxjs';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  @Input() type: string = '';
  @Input() icon: string = '';
  @Input() customIcon: boolean = false;
  @Input() exit = false;
  @Input() message: string = '';
  @Input() id: string = '';
  @Input() idMessage: string = '';
  @Input() time: number | null = null;
  @Input() hyperlinkText: string = '';
  @Input() hyperlinkId: string = '';
  @Input() list: Array<{ message: string }> = [];

  @Output() hyperlink = new EventEmitter();

  showNotification = true;
  @Output() showChange = new EventEmitter();

  @Input()
  get show() {
    return this.showNotification;
  }

  set show(val) {
    if (this.time != null && val) {
      const source = timer(this.time);
      const susb = source.subscribe((f:number) => {
        this.show = false;
        susb.unsubscribe()
      });
    }
    this.showNotification = val;
    this.showChange.emit(this.showNotification);
  }

  constructor() { }

  ngOnInit() {
  }
  closeNotification() {
    this.show = false;
  }
  clickHyperlink() {
    this.hyperlink.emit();
  }

}
