import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  @Input() type: string = '';
  @Input() icon: string = '';
  @Input() label: string = '';
  @Input() id: string = '';
  @Input() disabled: boolean =  false;
  @Input() typeAtributte: string = '';
  

  constructor() {
  }

  ngOnInit() {
    if(!this.typeAtributte){
      this.typeAtributte = 'button';
    }
  }

}
