<div class="modal-container {{style}}" [style.display]=display id="{{ id }}" [ngStyle]="{'z-index': zIndex}">
  <div class="centered">
    <div class="modal-content" [style.height]=height [style.width]=width>
      <div *ngIf="titleModal" class="row modal-header align-items-center">
        <div class="col text-center pr-0">
          <h2 id="{{idTitle}}"> {{ titleModal }}</h2>
        </div>
        <div *ngIf="iconClosed" class="col-1 text-rigth close">
          <i class="{{iconClosed}}" (click)="closeModal()" id="btn_close"></i>
        </div>
      </div>
      <ng-content></ng-content>
    </div>
  </div>
</div>
