import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslateLanguageService {

  public language = 'es';
  observableLenguage = new BehaviorSubject(this.language);

  constructor(public translateService: TranslateService) {
    this.initTranslate();
  }

  initTranslate() {
    this.translateService.use('es');
    this.translateService.onTranslationChange.emit();
  }

  changeLanguage() {
    if (this.language === 'en') {
      this.translateService.use('es');
      this.language = 'es';
    } else {
      this.translateService.use('en');
      this.language = 'en';
    }
    this.eventChange();
  }

  async getTranslation(key:string|undefined, value?:any) {
    let translation = key;
    if (translation !== '' && translation !== undefined) {
      await this.translateService.get('key').toPromise().then(
        () => {
          if (value) {
            translation = this.translateService.instant(`${translation}`, { value });
          } else {
            translation = this.translateService.instant(`${translation}`);
          }
        }
      );
    }
    return translation;
  }

  eventChange() {
    this.observableLenguage.next(this.language);
  }
}
