import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-password-validator',
  templateUrl: './password-validator.component.html',
  styleUrls: ['./password-validator.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PasswordValidatorComponent), multi: true }
  ]
})
export class PasswordValidatorComponent {

  textInput = 'input-normal';
  @Input() style = this.textInput;
  styleConfirm = this.textInput;
  value = '';
  valueConfirm = '';
  inputState = '';
  inputStateConfirm = 'disabled';

  state = {
    length: false,
    special: false,
    result: false,
    number: false,
    username: false,
    lower: false,
    upper: false,
    usernameContains: true
  };

  @Input() error: boolean;
  @Input() label: string;
  @Input() labelConfirm: string;
  @Input() lengthValidationMessage: string;
  @Input() letterAndNumberValidationMessage: string;
  @Input() validationUpperAndLower: string;
  @Input() doNotMatchUserNameMessage: string;
  @Input() validateContainUsernameMessaje = '';
  @Input() specialCharacterMessage: string;
  @Input() labelError: string;
  @Input() labelErrorConfirm: string;
  @Input() labelLevelLow: string;
  @Input() labelLevelmedium: string;
  @Input() labelLevelHigh: string;
  @Input() id: string;
  @Input() minLength: number;
  @Input() maxLength: number;
  @Output() statusChanged = new EventEmitter<string>();

  isValid = false;
  isValidators = false;
  isRulesOk = true;
  isValidConfirm = false;
  isLevel = false;
  visiblePass = false;
  visiblePassConfirm = false;

  level: any;

  public class = 'fal fa-eye';
  public type = 'password';
  public typeConfirm = 'password';
  public classConfirm = 'fal fa-eye';
  isEquals = false;
  isNotEquals = false;

  @Input() username: string;
  @Input() levelMessage: string;

  @Output() setState: EventEmitter<Object> = new EventEmitter();

  onChange: (_: any) => void = (_: any) => { };
  onTouched: () => void = () => { };

  constructor() { }

  onFocus() {
    this.emitStatusChange('onFocus1');
    this.style = 'input-has-focus';
    if (this.state.result && this.isValid) {
      this.isValidators = false;
    } else {
      this.isValidators = true;
    }
  }

  onFocusOut() {
    this.emitStatusChange('onFocusOut1');
    if (this.value === '') {
      this.style = this.textInput;
    } else {
      this.style = 'input-has-value';
    }
    if (this.state.result) {
      this.inputState = '';
      this.isRulesOk = true;
    } else {
      this.inputState = 'error';
      this.isRulesOk = false;
      this.emitStatusChange('rules not ok');
    }
    this.validateMatch();
  }

  onFocusConfirm() {
    this.emitStatusChange('onFocus2');
    this.styleConfirm = 'input-has-focus';
  }

  validateSpecialCharacter(event: any) {
    if (event.key === "'" ||
      event.key === '\\' ||
      event.key === '<' ||
      event.key === ' ' ||
      event.key === '>' ||
      event.key === '"' ||
      event.key === '&' ||
      event.key === '-') {
      event.preventDefault();
    }
  }

  onFocusOutConfirm() {
    this.emitStatusChange('onFocusOut2');
    if (this.valueConfirm === '') {
      this.styleConfirm = this.textInput;
    } else {
      this.styleConfirm = 'input-has-value';
    }
    this.validateMatch();
  }

  updateChanges() {
    this.onChange(this.value);
  }

  writeValue(value: string): void {
    this.value = value;
    this.updateChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  getState(event:any) {
    this.state = event.state;
    this.validateContainsUsernameState();
    if (this.state.result) {
      this.inputStateConfirm = '';
      this.isValid = true;
      this.isValidators = false;
      this.inputState = '';
      this.isRulesOk = true;
      this.emitStatusChange('rules ok');
      this.isLevel = true;
    } else {
      this.inputStateConfirm = 'disabled';
      this.isValid = false;
      this.isValidators = true;
      this.isLevel = false;
    }
  }

  validateContainsUsernameState() {
    if (this.state.lower &&
      this.state.length &&
      this.state.number &&
      !this.state.result &&
      this.state.special &&
      this.state.upper &&
      this.state.username &&
      !this.state.usernameContains &&
      this.validateContainUsernameMessaje === '') {
      this.state.result = true;
    }
  }

  ifIsEquals() {
    this.inputStateConfirm = '';
    this.isEquals = false;
    if (this.value === this.valueConfirm && this.value.length > 0 && this.state.result) {
      this.isValidConfirm = true;
      this.isEquals = false;
      this.setState.emit(true);
    } else {
      this.isEquals = true;
      this.isValidConfirm = false;
      this.setState.emit(false);
    }
  }

  validateMatch() {
    if (this.valueConfirm !== undefined && this.valueConfirm !== '') {
      if (this.value === this.valueConfirm) {
        this.inputStateConfirm = '';
        this.isEquals = false;
        this.emitStatusChange('equals true');
      } else {
        this.inputStateConfirm = 'error';
        this.isEquals = true;
        this.emitStatusChange('equals false');
      }
    }
  }
  setLevel(event: any) {
    if (event.state.result) {
      this.level = event.state;
      this.level.label = this.level.level === 'low' ? this.labelLevelLow
        : this.level.level === 'medium' ? this.labelLevelmedium : this.level.level === 'high' ? this.labelLevelHigh : '';
    } else {
      this.level = null;
    }
  }

  private emitStatusChange(message: string) {
    this.statusChanged.emit(message);
  }

  public togglePassWordConfirm() {
      this.visiblePassConfirm = !this.visiblePassConfirm;
      if (this.visiblePassConfirm) {
        this.typeConfirm = 'text';
        this.classConfirm = 'fal fa-eye-slash';
      } else {
        this.typeConfirm = 'password';
        this.classConfirm = 'fal fa-eye';
      }
  }

  public togglePassword() {
    this.visiblePass = !this.visiblePass;
    if (this.visiblePass) {
      this.type = 'text';
      this.class = 'fal fa-eye-slash';
    } else {
      this.type = 'password';
      this.class = 'fal fa-eye';
    }
  }

  clearConfirmPassword() {
    this.valueConfirm = '';
  }

}
