<div class="row notification {{ type }} {{ exit ? '' : 'pl-0' }}" *ngIf="show" id="{{ id }}">
  <div class="col-1 icon">
    <i class=" {{ icon }}" *ngIf="customIcon" ></i>
    <i *ngIf="!customIcon" [ngClass]="{
    'icon-success': type == 'success' || type == 'success-inline', 
    'icon-error': type == 'error' || type == 'error-inline', 
    'icon-info' : type == 'info' || type == 'info-inline',
    'icon-warning': type == 'warning' || type == 'warning-inline' }" ></i>
  </div>
  <div class="{{ exit ? 'col-10' : 'col-11' }} content" id="{{idMessage}}">
    <span> {{ message }} <u><span *ngIf="hyperlinkText" class="hyperlink-text"
          (click)="clickHyperlink()">{{ hyperlinkText }}</span></u></span>
  </div>
  <div class="col-1 icon-exit" *ngIf="exit" id="close">
    <i class="fal fa-times" (click)="closeNotification()"></i>
  </div>

  <div class="col-12 container-list" *ngIf="list.length > 0">
    <ul *ngFor="let error of list; let i = index">
      <li id="Economic_Group_Error_{{i}}">
        {{error.message}}
      </li>
    </ul>
  </div>
</div>
