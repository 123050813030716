<div class="blocking-background" id="{{ id }}"></div>
<div class="container-loader">
  <div class="loader">
    <div class="spinner">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>
