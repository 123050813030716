import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public config = {
    token: {
      type: null,
      value: null,
      refresh: null,
      expire: null
    },
    channel: {
      clientId: null,
      grantType: null,
      secret: null,
      lang: null,
      version : null
    }
  };

  constructor() { }
}
