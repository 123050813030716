import { Paths } from '../app/settings/models/enviroment-consts/paths';


export const environment = {
  production: true,
  SECURITY: 'https://api.banistmo.com/securityv2/v2/security',
  SECURITY_OIDC: 'https://api.banistmo.com/securityv2/OIDC/v2.0',
  SITEKEYV2: '6LcVsp4mAAAAAOOqM6rAVpxhr93YLeeBX_5m5Map',
  CONTENT_MANAGEMENT: `https://api.banistmo.com${Paths.CONTENT_MANAGEMENT}`,
  CHANNEL_STATUS: `https://api.banistmo.com${Paths.CHANNEL_STATUS}`,
  TOKEN_GUEST: `https://api.banistmo.com${Paths.TOKEN_GUEST}`,
  EXPIRED_TOKEN: 'EXPIRED_TOKEN'
};
