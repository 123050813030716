import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

  @Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() id: string = '';
  @Input() titleModal: string = '';
  @Input() height: string = '';
  @Input() width: string = '';
  @Input() iconClosed: string = '';
  @Input() idTitle: string = '';
  @Input() style: string = '';
  @Input() zIndex = '3000';

  display: string = '';

  openModal() {
    this.display = 'block';
    document.body.style.overflow = 'hidden';
  }

  closeModal() {
    this.display = 'none';
    document.body.style.overflow = 'auto';
    this.closed.emit(true);

  }

}
