import { Component, OnInit, Input, Output, EventEmitter, DoCheck } from '@angular/core';

@Component({
  selector: 'app-password-field',
  templateUrl: './password-field.component.html',
  styleUrls: ['./password-field.component.scss']
})
export class PasswordFieldComponent implements OnInit, DoCheck {


  @Output() public valuePassword = new EventEmitter();
  @Input() label: string = '';
  @Input() state: string = '';
  @Input() message: string = '';
  @Input() tooltipMessage: string = '';
  @Input() type: string = '';
  @Input() patternInput: string = '';
  @Input() icon: string = '';
  @Input() id: string = '';
  @Input() maxLength: number = 0;
  @Input() minLength: number = 0;
  @Input() idEye: string = '';
  @Input() inputStyle: string = '';
  @Input() disabled: boolean = false;
  @Input() focusElement: boolean = false;
  @Input() clearInput: boolean = false;

  public class = 'fal fa-eye';
  valueInputPassword: any;
  timer:any = null;
  visiblePass = false;

  constructor() { }

  getValuePassword() {
    this.valuePassword.emit({
      value: this.valueInputPassword,
      state: this.inputStyle
    });
  }

  ngOnInit() {
    this.type = 'password';
  }

  ngDoCheck() {
    if (this.clearInput) {
      this.valueInputPassword = '';
    }
  }

  validateField() {
    if (this.valueInputPassword !== null && this.valueInputPassword !== '' && this.valueInputPassword !== undefined) {
      if (this.valueInputPassword.includes('´')) {
        const num = this.valueInputPassword.length;
        const value = this.valueInputPassword.substr(0, num - 1);
        this.valueInputPassword = value;
      }
      if (this.valueInputPassword.length >= this.minLength) {
        this.inputStyle = 'status';
        this.getValuePassword();
      } else {
        this.inputStyle = 'error';
      }
    } else {
      this.inputStyle = 'error';
    }
  }

  clearField() {
    this.valueInputPassword = '';
    this.state = '';
    this.message = '';
  }

  onSetStateChange(event:any) {
    this.state = event.state;
    this.inputStyle = event.state;
    if (event.state === 'error') {
      this.setError();
    } else if (event.state === 'empty') {
      this.inputStyle = 'error'
      this.state = '';
      this.message = '';
    } else {
      this.message = '';
    }
    if (this.valueInputPassword === '') {
      this.message = '';
      this.state = '';
    }
    this.getValuePassword();
  }

  clearErrorStyle() {
    this.validateField();
    if (this.state == 'error') {
      this.state = '';
      this.message = '';
    }

  }
  showErrorState() {
    clearTimeout(this.timer);
    this.timer = setTimeout(
      () => {
        if (this.inputStyle === 'error' && this.valueInputPassword !== '') { 
          
          this.setError();
        }
    }, 1000);
  }

  setError() {
    this.state = 'error';
    this.inputStyle = 'error';
    this.message = 'No cumple con la longitud mínima';
  }

  public togglePassword() {
    this.visiblePass = !this.visiblePass;
    if (this.visiblePass) {
      this.type = 'text';
      this.class = 'fal fa-eye-slash';
    } else {
      this.type = 'password';
      this.class = 'fal fa-eye';
    }
  }

}
