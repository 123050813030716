import { Component, Input, OnInit, EventEmitter, forwardRef } from '@angular/core';
import { Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';


@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TextFieldComponent),
    }
  ]
})
export class TextFieldComponent implements OnInit {

  @Input() public charsCounter = 0;
  timer:any = null;
  @Input() valueInput: string = '';
  @Input() inputStyle: string = '';
  @Output() public valueUsername = new EventEmitter();
  @Output() focusOut = new EventEmitter();
  @Input() label: string = '';
  @Input() iconPrimary: string = '';
  @Input() iconSecondary: string = '';
  @Input() state: string = '';
  @Input() message: string = '';
  @Input() tooltipMessage: string = '';
  @Input() idTooltip:string = '';
  @Input() patternInput: string = '';
  @Input() id: string = '';
  @Input() maxLength: number = 0;
  @Input() minLength: number = 0;
  @Input() type: string | undefined = '';
  @Input() counter = false;
  @Input() focusElement: boolean = false;
  @Input() typeTooltip?: string;
  @Input() imageTooltip?: string;
  onChange: (_: any) => void = (_: any) => { };
  onTouched: () => void = () => { };
  getValueUsername() {
    this.valueUsername.emit({
      value: this.valueInput,
      state: this.inputStyle
    });
  }

  constructor() {

  }
  updateChanges() {
    this.onChange(this.valueInput);
  }

  writeValue(value: string): void {
    this.valueInput = value;
    this.updateChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit() {
    if (this.type === '' || this.type === undefined) {
      this.type = 'text';
    }
    if (this.valueInput) {
      this.countChars({
        target: {
          value: this.valueInput
        }
      });
    }
  }

  focusOutEvent(event:any) {
    this.focusOut.emit({
      state: true,
      event: event
    });

  }

  validateField() {
    if (this.valueInput !== null && this.valueInput !== '' && this.valueInput !== undefined) {
      if (this.valueInput.includes('´') || this.checkSpaces(this.valueInput)) {
        const num = this.valueInput.length;
        const value = this.valueInput.substr(0, num - 1);
        this.valueInput = value;
      }
      if (this.valueInput.length >= this.minLength) {
        this.inputStyle = 'status';
        this.getValueUsername();
      } else {
        this.inputStyle = 'error';
      }

    } else {
      this.inputStyle = 'error';
    }
    this.getValueUsername();

  }

  onSetStateChange(event:any) {
    this.state = event.state;
    this.inputStyle = event.state;
    if (event.state === 'error') {
      this.setError();
    } else if (event.state === 'empty') {
      this.inputStyle = 'error';
      this.state = '';
      this.message = '';
      this.iconSecondary = '';
    } else {
      this.message = '';
      this.iconSecondary = '';
    }

    if (this.valueInput === '') {
      this.message = '';
      this.state = '';
      this.iconSecondary = '';
    }

    this.getValueUsername();
  }

  clearField() {
    if (this.iconSecondary === 'fal fa-times') {
      this.message = '';
      this.state = '';
      this.iconSecondary = '';
      this.valueInput = '';
    }
  }
  clearErrorStyle() {
    this.validateField();
    if (this.state === 'error') {
      this.state = '';
      this.iconSecondary = '';
      this.message = '';
    }
  }

  showErrorState() {
    clearTimeout(this.timer);
    this.timer = setTimeout(
      () => {
        if (this.inputStyle === 'error' && this.valueInput !== '') {

          this.setError();
        }
      }, 1000);
  }

  setError() {
    this.state = 'error';
    this.message = 'No cumple con la longitud mínima';
    this.iconSecondary = 'fal fa-times';
  }

  public countChars(event:any): void {
    this.charsCounter = this.valueInput.length;
  }

  /**
   * Checks if the value ends with 2 whitespaces
   */
  private checkSpaces(value: string): boolean {
    const regExp = /\s\s$/;
    return regExp.test(value);
  }

}
