<div id="floatContainer" class="container {{inputState}} pr-0" [ngClass]="style">
  <label>
    <span for="floatField">{{ label }}</span>
    <input id="{{ id ? id + 'Input' : 'floatField' }}" type="{{ type }}" [(ngModel)]="value" validatePassGeneration (setLevel)="getState($event)" (level)="setLevel($event)"
      (ngModelChange)="updateChanges()" (focus)="onFocus()" (focusout)="onFocusOut()" autocomplete="off" onPaste="return false"
      onCopy="return false" [minLength]="minLength" [maxLength]="maxLength" (keydown)="validateSpecialCharacter($event)">
    <div class="icon">
      <i id="idEye" [class]="class" (click)="togglePassword()"></i>
      <i class="fal fa-check" *ngIf="isValid"></i>
    </div>
  </label>
</div>
<p *ngIf="!isRulesOk" class="message-error" id="errorMessage">{{labelError}}</p>
<progress-bar-pass [level]="level" id="SecurityLevel" *ngIf="isLevel" ></progress-bar-pass>
<div *ngIf="isValidators">
  <p id="lbl_pass_length">
    <i id="lbl_pass_length_error" class="fal fa-times" *ngIf="!state.length"></i>
    <i id="lbl_pass_length_ok" class="fal fa-check" *ngIf="state.length"></i>
    {{lengthValidationMessage}}</p>
  <p id="lbl_pass_length">
     <i id="lbl_pass_length_error" class="fal fa-times" *ngIf="!state.upper || !state.lower"></i>
     <i id="lbl_pass_length_ok" class="fal fa-check" *ngIf="state.upper && state.lower"></i>
     {{validationUpperAndLower}}</p>
  <p id="lbl_pass_number">
    <i id="lbl_pass_number_error" class="fal fa-times" *ngIf="!state.number"></i>
    <i id="lbl_pass_number_ok" class="fal fa-check" *ngIf="state.number"></i>
    {{letterAndNumberValidationMessage}}</p>
  <p id="lbl_pass_username">
    <i id="lbl_pass_username_error" class="fal fa-times" *ngIf="!state.username"></i>
    <i id="lbl_pass_username_ok" class="fal fa-check" *ngIf="state.username"></i>
    {{doNotMatchUserNameMessage}}</p>

    <p id="lbl_pass_username_contains" *ngIf="validateContainUsernameMessaje != ''">
      <i id="lbl_pass_username_contains_error" class="fal fa-times" *ngIf="!state.usernameContains"></i>
      <i id="lbl_pass_username_contains_ok" class="fal fa-check" *ngIf="state.usernameContains"></i>
      {{validateContainUsernameMessaje}}</p>

  <p id="lbl_pass_special">
    <i id="lbl_pass_special_error" class="fal fa-times" *ngIf="!state.special"></i>
    <i id="lbl_pass_special_ok" class="fal fa-check" *ngIf="state.special"></i>
    {{specialCharacterMessage}}</p>
</div>
<br>
<div id="floatContainerConfirm" class="container pr-0 {{inputStateConfirm}}" [ngClass]="styleConfirm">
  <label>
    <span for="floatFieldConfirm">{{ labelConfirm }}</span>
    <input id="{{ id ? id + 'InputConfirm' : 'floatFieldConfirm' }}" [disabled]="(inputStateConfirm == 'disabled')" type="{{ typeConfirm }}" [(ngModel)]="valueConfirm"
      (input)="ifIsEquals()" validatePassGeneration (focus)="onFocusConfirm()" (focusout)="onFocusOutConfirm()"
      autocomplete="off" onPaste="return false" onCopy="return false" [minLength]="minLength" [maxLength]="maxLength" >
    <div class="icon">
      <i id="idEye" [class]="classConfirm" (click)="togglePassWordConfirm()"></i>
      <i id="idEyeConfirm" class="fal fa-check" *ngIf="!isEquals"></i>
      <i id="idEyeConfirm" class="fal fa-times" *ngIf="isEquals" (click)="clearConfirmPassword()"></i>
    </div>
  </label>

</div>
<p *ngIf="isEquals" class="message-error" id="{{ id ? id + 'Message' : 'errorMessageConfirm' }}">
  {{ labelErrorConfirm }}
</p>
