import { environment } from '../../../../environments/environment';

export class ApiGateway {
  public static SECURITY = environment.SECURITY;
  public static SECURITY_OIDC = environment.SECURITY_OIDC;
  public static SITEKEYV2 = environment.SITEKEYV2;
  public static readonly CONTENT_MANAGEMENT = environment.CONTENT_MANAGEMENT;
  public static CHANNEL_STATUS = environment.CHANNEL_STATUS;
  public static TOKEN_GUEST = environment.TOKEN_GUEST;
}
