import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-step-progress',
  templateUrl: './step-progress.component.html',
  styleUrls: ['./step-progress.component.scss']
})
export class StepProgressComponent {

  @Input() idStepProgress: string = '';
  @Input() progressTitle: string = '';
  @Input() showing: number = 0;
  @Input() newVersion = false;
  @Input() status = 'status-container';
  @Input() forceSmall: boolean = false;
  constructor() { }

}

// TODO:
// 1. Update the registration form to use the new svg version
// 2. Delete the newVersion member
