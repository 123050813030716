<div class="text-field {{ state }}">
  <div class="content">
    <label>
      <input 
        (ngModelChange)="updateChanges()"
        type="{{type}}" 
        id="{{ id }}" 
        class="{{ inputStyle }}" 
        [(ngModel)]="valueInput" 
        name="text-field" 
        [required]="true" 
        (keyup)="clearErrorStyle(); showErrorState(); countChars($event)" 
        (change)="validateField()"
        input-directive 
        [minLength]=minLength 
        [maxLength]=maxLength 
        [pattern]="patternInput" 
        (setState)="onSetStateChange($event)" 
        (click)="validateField()" 
        [disabled]="(state == 'disabled')" 
        autocomplete="off" 
        onPaste="return false" 
        onCopy="return false"
        (blur)="focusOutEvent($event)"
        [autofocus]='focusElement'
      >
      <span>{{ label }}</span>
      <div class="icon">
        <app-tooltip *ngIf="tooltipMessage || (imageTooltip &&( valueInput === undefined || valueInput === ''))" icon="{{ iconPrimary }}" idTooltip="{{idTooltip}}" title="{{tooltipMessage}}" type="{{typeTooltip}}" image="{{imageTooltip}}"></app-tooltip>
        <i class="{{ iconSecondary }}" (click)="clearField()"></i>
      </div>
    </label>
  </div>
  <div class="sub-content">
    <p class="message">{{ message }}</p>
    <p *ngIf="counter">{{ charsCounter }}/{{ maxLength }}</p>
  </div>
</div>
