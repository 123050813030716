import {Component, Input} from '@angular/core';

@Component({
  selector: 'progress-bar-pass',
  templateUrl: './progress-bar-pass.html',
  styleUrls: ['./progress-bar-pass.scss']
})
export class ProgressBarPassComponent {

  @Input() level: any;
  @Input() levelMessage: string;
  text: string;

  constructor() {
  }

}
