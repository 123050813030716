<div *ngIf="newVersion">
  <svg class="stepper-svg" [class.force-small]="forceSmall" xmlns="http://www.w3.org/2000/svg">
    <svg:line x1="1" y1="20" x2="319" y2="20" class="line" />
    <svg:g class="g-step step-n1" [class.active]="showing === 1">
      <svg:circle r="9" cx="10" cy="10" />
      <svg:circle r="6" cx="10" cy="10" />
      <svg:text x="29" y="5" id="{{ idStepProgress }}-label1">
        {{ progressTitle }}
      </svg:text>
    </svg:g>
    <svg:g class="g-step step-n2 text-anchor-end" [class.active]="showing === 2">
      <svg:circle r="9" cx="10" cy="10" />
      <svg:circle r="6" cx="10" cy="10" />
      <svg:text x="-7" y="5" id="{{ idStepProgress }}-label2">
        {{ progressTitle }}
      </svg:text>
    </svg:g>
    <svg:g class="g-step step-n3 text-anchor-end" [class.active]="showing === 3">
      <svg:circle r="9" cx="10" cy="10" />
      <svg:circle r="6" cx="10" cy="10" />
      <svg:text x="-7" y="5" id="{{ idStepProgress }}-label3">
        {{ progressTitle }}
      </svg:text>
    </svg:g>
  </svg>
</div>
<div *ngIf="!newVersion">
  <div class="{{status}}" *ngIf="showing === 1" id="{{ idStepProgress }}">
    <div class="first-circle"></div>
    <div class="first-circle-background"></div>
    <div class="first-line">{{ progressTitle }}</div>
    <div class="first-second-circle"></div>
    <div class="first-second-line"></div>
    <div class="first-third-circle"></div>
    <div class="first-third-line"></div>
    <div class="forth-circle"></div>
  </div>
  <div class="{{status}}" *ngIf="showing === 2" id="{{ idStepProgress }}">
    <div class="first-circle"></div>
    <div class="second-line"></div>
    <div class="second-second-circle"></div>
    <div class="second-circle-background"></div>
    <div class="second-second-line">{{ progressTitle }}</div>
    <div class="second-third-circle"></div>
    <div class="second-third-line"></div>
    <div class="forth-circle"></div>
  </div>

  <div class="{{status}}" *ngIf="showing === 3" id="{{ idStepProgress }}">
    <div class="first-circle"></div>
    <div class="forth-line"></div>
    <div class="forth-second-circle"></div>
    <div class="third-second-line">{{ progressTitle }}</div>
    <div class="third-third-circle"></div>
    <div class="third-circle-background"></div>
    <div class="third-third-line"></div>
    <div class="forth-circle"></div>
  </div>
  <div class="{{status}}" *ngIf="showing === 4" id="{{ idStepProgress }}">
    <div class="first-circle"></div>
    <div class="forth-line"></div>
    <div class="forth-second-circle"></div>
    <div class="forth-second-line"></div>
    <div class="forth-third-circle"></div>
    <div class="forth-third-line">{{ progressTitle }}</div>
    <div class="forth-circle"></div>
    <div class="forth-circle-background"></div>
  </div>
</div>
