import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BusService {

  public readonly parentWindow: Window;

  constructor() {
    this.parentWindow = window.parent;
  }

  public postMessage(message: any, targetOrigin = '*', transfer?: any): void {
    this.parentWindow.postMessage(message, targetOrigin, transfer);
  }

  public startLoading() {
    this.postMessage({ loading: true });
  }

  public stopLoading() {
    this.postMessage({ loading: false });
  }

  public requestToken() {
    this.postMessage({ token: true });
  }

  public sendHeight(height: number) {
    this.postMessage({ height });
  }

}
